<script setup>
import {
  RadioGroup,
  RadioGroupDescription,
  RadioGroupLabel,
  RadioGroupOption
} from '@headlessui/vue'
import { computed, ref } from 'vue'
import { convertBytesToGigabytes } from '@/helpers/convert-bytes-to-gigabytes'
import { formatCurrency } from '@/helpers/currency'

const props = defineProps({
  sizes: {
    type: Array,
    required: true,
    default: () => []
  },
  modelValue: {
    type: Object,
    required: true
  }
})
const emit = defineEmits(['update:modelValue'])

const model = computed({
  get() {
    return props.modelValue
  },
  set(value) {
    emit('update:modelValue', value)
  }
})

// use exchangeRate
const exchangeRate = ref(4)
const isLoadingExchangeRate = ref(false)

async function fetchExchangeRate() {
  isLoadingExchangeRate.value = true
  try {
    const response = await fetch(
      `https://economia.awesomeapi.com.br/json/all/USD-BRL`
    )
    const json = await response.json()
    exchangeRate.value = json.USD.ask
  } catch (error) {
    console.log(error)
  } finally {
    isLoadingExchangeRate.value = false
  }
}

fetchExchangeRate()
</script>

<template>
  <RadioGroup v-model="model">
    <RadioGroupLabel class="sr-only">
      Server size
    </RadioGroupLabel>
    <div class="space-y-4">
      <RadioGroupOption
        v-for="size in sizes"
        :key="size.name"
        v-slot="{ checked, active }"
        as="template"
        :value="size"
      >
        <div
          class="relative block cursor-pointer rounded-lg border bg-background px-6 py-4 shadow-sm focus:outline-none sm:flex sm:justify-between"
          :class="[
            checked ? 'border-transparent' : 'border-border',
            active ? 'border-primary ring-2 ring-primary' : '',
          ]"
        >
          <span class="flex items-center">
            <span class="flex flex-col text-sm">
              <RadioGroupLabel
                as="span"
                class="font-medium "
              >{{
                size.size_category?.name || size.name
              }}</RadioGroupLabel>
              <RadioGroupDescription
                as="span"
                class="text-muted-foreground"
              >
                <span class="block sm:inline">{{ convertBytesToGigabytes(size.memory_in_bytes) }} GB RAM /
                  {{ size.cpu_count }} CPU</span>
                {{ " " }}
                <span
                  class="hidden sm:mx-1 sm:inline"
                  aria-hidden="true"
                >&middot;</span>
                {{ " " }}
                <span class="block sm:inline">{{ convertBytesToGigabytes(size.disk_in_bytes) }} GB SSD
                </span>
              </RadioGroupDescription>
            </span>
          </span>
          <RadioGroupDescription
            as="span"
            class="mt-2 flex text-sm sm:ml-4 sm:mt-0 sm:flex-col sm:text-right"
          >
            <span class="font-medium ">{{ formatCurrency(size.price_per_month, "USD") }} / {{ $t('common.month') }}</span>
            <span class="ml-1 text-xs text-muted-foreground sm:ml-0">
              {{
                $t('pages.server_size_radio_group.approximately')
              }}
              {{ formatCurrency(size.price_per_month * exchangeRate) }} / {{ $t('common.month') }}
            </span>
          </RadioGroupDescription>
          <span
            class="pointer-events-none absolute -inset-px rounded-lg"
            :class="[
              active ? 'border' : 'border-2',
              checked ? 'border-primary' : 'border-transparent',
            ]"
            aria-hidden="true"
          />
        </div>
      </RadioGroupOption>
    </div>
  </RadioGroup>
</template>
