<script setup lang="ts">
import { ComputerDesktopIcon, DevicePhoneMobileIcon, DeviceTabletIcon } from '@heroicons/vue/24/solid'
import { ToggleGroup, ToggleGroupItem } from '@/components/ui/toggle-group'
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '@/components/ui/tooltip'

const model = defineModel({
  required: true,
})
</script>

<template>
  <ToggleGroup
    v-model="model"
    type="multiple"
  >
    <ToggleGroupItem
      value="smartphone"
      aria-label="Toggle smartphone"
      class="size-16"
    >
      <TooltipProvider>
        <Tooltip>
          <TooltipTrigger as-child>
            <DevicePhoneMobileIcon class="size-12" />
            <input
              id="smartphone"
              v-model="model"
              type="checkbox"
              value="smartphone"
              name="device"
              class="sr-only"
            >
          </TooltipTrigger>
          <TooltipContent>
            <p>
              {{ $t('common.devices.smartphone') }}
            </p>
          </TooltipContent>
        </Tooltip>
      </TooltipProvider>
    </ToggleGroupItem>
    <ToggleGroupItem
      value="tablet"
      aria-label="Toggle tablet"
      class="size-16"
    >
      <TooltipProvider>
        <Tooltip>
          <TooltipTrigger as-child>
            <DeviceTabletIcon class="size-12" />
            <input
              id="tablet"
              v-model="model"
              type="checkbox"
              value="tablet"
              name="device"
              class="sr-only"
            >
          </TooltipTrigger>
          <TooltipContent>
            <p>
              {{ $t('common.devices.tablet') }}
            </p>
          </TooltipContent>
        </Tooltip>
      </TooltipProvider>
    </ToggleGroupItem>
    <ToggleGroupItem
      value="desktop"
      aria-label="Toggle desktop"
      class="size-16"
    >
      <TooltipProvider>
        <Tooltip>
          <TooltipTrigger as-child>
            <ComputerDesktopIcon class="size-12" />
            <input
              id="desktop"
              v-model="model"
              type="checkbox"
              value="desktop"
              name="device"
              class="sr-only"
            >
          </TooltipTrigger>
          <TooltipContent>
            <p>
              {{ $t('common.devices.desktop') }}
            </p>
          </TooltipContent>
        </Tooltip>
      </TooltipProvider>
    </ToggleGroupItem>
  </ToggleGroup>
</template>
