<script setup>
import { useI18n } from 'vue-i18n'
import Badge from '@/components/ui/badge/Badge.vue'

const props = defineProps({
  verified: {
    type: Boolean,
    default: false
  }
})

const { t } = useI18n()

function getText() {
  return props.verified ? t('components.domain_status_badge.verified') : t('components.domain_status_badge.unverified')
}

function getColor() {
  return props.verified ? 'success' : 'secondary'
}
</script>

<template>
  <Badge
    :variant="getColor()"
    size="xs"
  >
    {{ getText() }}
  </Badge>
</template>
