<script setup>
import { ref, watch } from 'vue'
import { toast } from 'vue-sonner'
import { ExclamationTriangleIcon } from '@heroicons/vue/24/solid'
import { useI18n } from 'vue-i18n'
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger
} from '@/components/ui/dialog'
import { domainService } from '@/services/domain.js'
import Button from '@/components/ui/button/Button.vue'
import DomainCnameTable from '@/components/partials/domains/DomainCnameTable.vue'
import Card from '@/components/ui/card/Card.vue'
import { Alert, AlertDescription, AlertTitle } from '@/components/ui/alert'

const props = defineProps({
  domain: {
    type: Object,
    required: true,
  }
})

const emit = defineEmits(['verified'])
const { t } = useI18n()

const isLoading = ref(false)
const isOpen = ref(false)
const error = ref(undefined)

async function verifyDomain(id) {
  isLoading.value = true
  error.value = undefined

  try {
    await domainService.verify(id)

    toast.success(t('components.verify_domain_modal.toast.success.title'), {
      description: t('components.verify_domain_modal.toast.success.description'),
    })

    isOpen.value = false

    emit('verified')
  } catch (e) {
    if (e.response?.status === 400) {
      error.value = e.response.data?.message
    }

    toast.error(e.response?.data?.message, {
      description: e.response?.data?.action || e.message,
    })
  } finally {
    isLoading.value = false
  }
}

watch(isOpen, (value) => {
  if (!value) {
    error.value = undefined
  }
})
</script>

<template>
  <Dialog v-model:open="isOpen">
    <DialogTrigger>
      <Button
        type="button"
        variant="secondary"
      >
        {{ $t('components.verify_domain_modal.button') }}
      </Button>
    </DialogTrigger>
    <DialogContent>
      <DialogHeader>
        <DialogTitle>
          {{ $t('components.verify_domain_modal.title') }}
        </DialogTitle>
        <DialogDescription>
          {{
            $t('components.verify_domain_modal.description')
          }}
        </DialogDescription>
      </DialogHeader>

      <div class="mt-4 space-y-4">
        <div class="space-y-2">
          <p class="text-sm text-muted-foreground">
            {{
              $t('components.verify_domain_modal.instructions')
            }}
          </p>

          <Card>
            <DomainCnameTable
              :name="props.domain.name"
            />
          </Card>
        </div>

        <!-- Errors -->
        <Alert
          v-if="error"
          variant="destructive"
        >
          <ExclamationTriangleIcon class="size-4" />
          <AlertTitle>{{ $t('common.attention') }}</AlertTitle>
          <AlertDescription>
            {{ error }}
          </AlertDescription>
        </Alert>

        <Button
          block
          :loading="isLoading"
          :disabled="isLoading"
          @click="verifyDomain(props.domain.id)"
        >
          {{ $t('components.verify_domain_modal.button') }}
        </Button>
      </div>
    </DialogContent>
  </Dialog>
</template>
