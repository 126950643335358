<script setup>
import { onMounted, ref, watch } from 'vue'
import dayjs from 'dayjs'
import { toast } from 'vue-sonner'
import { useRoute, useRouter } from 'vue-router'
import { useHead } from '@unhead/vue'
import { useI18n } from 'vue-i18n'
import DatePicker from '@/components/base/DatePicker.vue'
import PageHeading from '@/components/common/PageHeading.vue'
import { campaignService } from '@/services/campaign'
import { usePresetDates } from '@/composables/usePresetDates'
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbPage,
  BreadcrumbSeparator,
} from '@/components/ui/breadcrumb'
import RequestsPerDayChart from '@/components/partials/RequestsPerDayChart.vue'
import RequestsPerCountry from '@/components/partials/RequestsPerCountry.vue'
import RequestByDeniedReason from '@/components/partials/RequestByDeniedReason.vue'
import CampaignSelect from '@/components/partials/campaign/CampaignSelect.vue'

const { t, locale } = useI18n()
useHead({
  title: t('pages.reports.title')
})
const {
  minDate,
  shortcuts
} = usePresetDates()
const router = useRouter()
const route = useRoute()

const range = ref({
  start: route.query.start_date || dayjs().startOf('day').format('YYYY-MM-DD HH:mm:ss'),
  end: route.query.end_date || dayjs().endOf('day').format('YYYY-MM-DD HH:mm:ss')
})
const campaigns = ref([])
const selectedCampaign = ref(route.query.campaign)
const isLoadingCampaigns = ref(false)

async function fetchCampaigns() {
  isLoadingCampaigns.value = true
  try {
    const { data } = await campaignService.findAll({
      limit: 100,
      page: 1
    })

    campaigns.value = data.data
  } catch (e) {
    toast.success('Sucesso!', {
      description: 'Campanhas carregadas com sucesso',
    })
  } finally {
    isLoadingCampaigns.value = false
  }
}

onMounted(() => {
  fetchCampaigns()
})

watch(range, () => {
  router.push({
    query: {
      ...route.query,
      start_date: range.value.start,
      end_date: range.value.end,
    }
  })
}, {
  deep: true
})

watch(() => selectedCampaign.value, () => {
  router.push({
    query: {
      ...route.query,
      campaign: selectedCampaign.value,
    }
  })
}, {
  deep: true
})
</script>

<template>
  <div class="space-y-5">
    <div class="space-y-2">
      <Breadcrumb>
        <BreadcrumbList>
          <BreadcrumbItem>
            <BreadcrumbLink as-child>
              <RouterLink to="/">
                {{ $t('pages.reports.breadcrumb.dashboard') }}
              </RouterLink>
            </BreadcrumbLink>
          </BreadcrumbItem>

          <BreadcrumbSeparator />

          <BreadcrumbItem>
            <BreadcrumbPage>
              {{ $t('pages.reports.breadcrumb.reports') }}
            </BreadcrumbPage>
          </BreadcrumbItem>
        </BreadcrumbList>
      </Breadcrumb>

      <PageHeading
        :title="t('pages.reports.title')"
      >
        <template #actions>
          <div class="grid grid-cols-1 items-center gap-2 lg:grid-cols-2">
            <CampaignSelect
              v-model="selectedCampaign"
              :options="campaigns"
            />
            <div class="w-72">
              <DatePicker
                v-model="range"
                :presets="shortcuts"
                :min="minDate"
                range
                :locale="locale"
              />
            </div>
          </div>
        </template>
      </PageHeading>
    </div>

    <div class="space-y-5">
      <RequestsPerDayChart
        :campaign-id="$route.query.campaign"
        :start-date="$route.query.start_date"
        :end-date="$route.query.end_date"
      />

      <RequestsPerCountry
        :campaign-id="$route.query.campaign"
        :start-date="$route.query.start_date"
        :end-date="$route.query.end_date"
      />

      <RequestByDeniedReason
        :campaign-id="$route.query.campaign"
        :start-date="$route.query.start_date"
        :end-date="$route.query.end_date"
      />
    </div>
  </div>
</template>
