<script setup>
import { ref } from 'vue'
import { useRouter } from 'vue-router'
import { useColorMode } from '@vueuse/core'
import { ChevronUpDownIcon } from '@heroicons/vue/24/solid'
import { Avatar, AvatarFallback } from '@/components/ui/avatar'
import { useUserStore } from '@/stores/user'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu'
import { useAuthStore } from '@/stores/auth'
import { Switch } from '@/components/ui/switch'

const userStore = useUserStore()
const authStore = useAuthStore()
const router = useRouter()
const mode = useColorMode()

const isQuitting = ref(false)

async function logout() {
  try {
    isQuitting.value = true
    await authStore.signOut()
    await router.push({
      name: 'SignIn'
    })
  } catch (error) {
    console.log('error', error)
  } finally {
    isQuitting.value = false
  }
}

function changeTheme() {
  mode.value = mode.value === 'dark' ? 'light' : 'dark'
}
</script>

<template>
  <DropdownMenu>
    <DropdownMenuTrigger class="w-full">
      <div
        class="flex w-full items-center justify-between gap-x-4 px-4 py-3 text-sm font-semibold leading-6 hover:bg-primary/10"
      >
        <div class="flex items-center gap-x-2">
          <Avatar class="size-8">
            <AvatarFallback>
              {{ userStore.user?.name[0] }}
            </AvatarFallback>
          </Avatar>
          <span class="sr-only">Your profile</span>
          <span
            aria-hidden="true"
            class="max-w-[180px] truncate"
          >
            {{ userStore.user?.name }}
          </span>
        </div>

        <ChevronUpDownIcon class="size-5 text-muted-foreground" />
      </div>
    </DropdownMenuTrigger>
    <DropdownMenuContent class="w-56">
      <DropdownMenuLabel>
        <div class="flex flex-col space-y-1">
          <p class="text-sm font-medium leading-none">
            {{ userStore.user?.name }}
          </p><p class="text-xs leading-none text-muted-foreground">
            {{ userStore.user?.email }}
          </p>
        </div>
      </DropdownMenuLabel>
      <DropdownMenuSeparator />
      <DropdownMenuGroup>
        <DropdownMenuItem as-child>
          <router-link
            to="/settings/profile"
          >
            {{ $t('components.profile.profile') }}
          </router-link>
        </DropdownMenuItem>
        <DropdownMenuItem as-child>
          <router-link to="/settings/subscriptions">
            {{ $t('components.profile.subscriptions') }}
          </router-link>
        </DropdownMenuItem>
        <DropdownMenuItem as-child>
          <router-link
            :to="{ name: 'dashboard.referral.index' }"
          >
            {{ $t('components.profile.referral') }}
          </router-link>
        </DropdownMenuItem>
        <div class="relative flex w-full cursor-default select-none items-center justify-between rounded-sm px-2 py-1.5 text-sm outline-none transition-colors focus:bg-accent focus:text-accent-foreground data-[disabled]:pointer-events-none data-[disabled]:opacity-50">
          Dark mode
          <Switch
            id="dark-mode"
            :checked="mode === 'dark'"
            @update:checked="changeTheme"
          />
        </div>
      </DropdownMenuGroup>
      <DropdownMenuSeparator />

      <DropdownMenuItem as-child>
        <a
          href="http://help.cloakup.me/"
          target="_blank"
        >
          {{ $t('components.profile.help') }}
        </a>
      </DropdownMenuItem>

      <DropdownMenuSeparator />
      <DropdownMenuItem @click="logout">
        <span>
          {{ $t('components.profile.logout') }}
        </span>
      </DropdownMenuItem>
    </DropdownMenuContent>
  </DropdownMenu>
</template>
