<script setup lang="ts">
import { toast } from 'vue-sonner'
import { ref } from 'vue'
import { useI18n } from 'vue-i18n'
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle
} from '@/components/ui/dialog'
import { Button } from '@/components/ui/button'
import { campaignService } from '@/services/campaign'

const props = defineProps({
  campaign: {
    type: Object,
    required: true,
  },
})

const emit = defineEmits(['convert'])
const { t } = useI18n()

const isOpen = defineModel({
  required: true,
  type: Boolean,
})
const isLoading = ref(false)

async function convertToBasic() {
  const converted = {
    name: props.campaign.name,
    active: props.campaign.active,
    alias: props.campaign.alias,
    domain: props.campaign.domain,
    pages: {
      white: {
        type: props.campaign.pages.white.type,
        content: props.campaign.pages.white.content
      },
      offers: props.campaign.pages.offers.map(offer => ({
        content: offer.content,
        share: offer.share,
        type: offer.type
      }))
    },
    filters: {
      tiktok: props.campaign.filters.tiktok,
      google: props.campaign.filters.google,
      facebook: props.campaign.filters.facebook,
      kwai: props.campaign.filters.kwai,
      bots: true,
      proxy: true,
      cloakup_ai: true,
      deny_always: false,
      adspy: true,
      geolocation: {
        allow: true,
        countries: props.campaign.filters.geolocation.countries
      },
      device: {
        allow: true,
        devices: props.campaign.filters.device.devices
      },
      query: {
        allow: true,
        params: props.campaign.filters.query.params,
        condition: 'some',
        remove_params: false
      },
      browser_language: {
        allow: true,
        languages: props.campaign.filters.browser_language.languages
      },
      referer: {
        block_null: false,
        allow: true,
        domains: []
      },
      domain: {
        allow: true,
        domains: []
      },
      isp: {
        allow: true,
        isps: []
      },
      os: {
        allow: true,
        os: []
      },
      user_agent: {
        allow: true,
        user_agents: []
      },
      blacklist: [],
      whitelist: []
    },
    mode: 'advanced'
  }

  try {
    isLoading.value = true
    await campaignService.update(props.campaign.id, converted)

    isOpen.value = false
    emit('convert')

    toast.success(t('components.campaign_convert_to_advanced_form.toast.success.title'), {
      description: t('components.campaign_convert_to_advanced_form.toast.success.description'),
    })
  } catch (error) {
    toast.error(error.response?.data?.message, {
      description: error.response?.data?.action || error.message,
    })
  } finally {
    isLoading.value = false
  }
}
</script>

<template>
  <Dialog v-model:open="isOpen">
    <DialogContent>
      <DialogHeader>
        <DialogTitle>
          {{ $t('components.campaign_convert_to_advanced_form.title') }}
        </DialogTitle>
        <DialogDescription>
          {{ $t('components.campaign_convert_to_advanced_form.description') }}
        </DialogDescription>
      </DialogHeader>
      <DialogFooter>
        <DialogClose>
          <Button variant="secondary">
            {{ $t('common.cancel') }}
          </Button>
        </DialogClose>
        <Button
          :disabled="isLoading"
          :loading="isLoading"
          @click="convertToBasic"
        >
          {{ $t('components.campaign_convert_to_advanced_form.button') }}
        </Button>
      </DialogFooter>
    </DialogContent>
  </Dialog>
</template>
