import dayjs from 'dayjs'
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'
import { useUserStore } from '@/stores/user'

export function usePresetDates() {
  const userStore = useUserStore()
  const { t } = useI18n()

  const maxRange = computed(
    () => Number(userStore.cloakupSubscription?.plan?.retention) || 30
  )

  const minDate = computed(() => {
    return dayjs().subtract(maxRange.value, 'days').startOf('day').toDate()
  })

  const shortcuts = computed(() => {
    const base = [
      {
        label: t('components.date_picker.today'),
        id: '1',
        value: {
          start: dayjs().startOf('day').format('YYYY-MM-DD HH:mm:ss'),
          end: dayjs().endOf('day').format('YYYY-MM-DD HH:mm:ss')
        },
      },
      {
        id: '2',
        value: {
          start: dayjs().startOf('day').subtract(1, 'day').format('YYYY-MM-DD HH:mm:ss'),
          end: dayjs().endOf('day').subtract(1, 'day').format('YYYY-MM-DD HH:mm:ss'),

        },
        label: t('components.date_picker.yesterday')
      },
      {
        id: '3',
        value: {
          start: dayjs().startOf('day').subtract(7, 'days').format('YYYY-MM-DD HH:mm:ss'),
          end: dayjs().endOf('day').format('YYYY-MM-DD HH:mm:ss'),
        },
        label: t('components.date_picker.last_7_days')
      },
      {
        id: '4',
        value: {
          start: dayjs().startOf('day').subtract(14, 'days').format('YYYY-MM-DD HH:mm:ss'),
          end: dayjs().endOf('day').format('YYYY-MM-DD HH:mm:ss'),
        },
        label: t('components.date_picker.last_14_days')
      },
      {
        id: '5',
        value: {
          start: dayjs().startOf('day').subtract(30, 'days').format('YYYY-MM-DD HH:mm:ss'),
          end: dayjs().endOf('day').format('YYYY-MM-DD HH:mm:ss'),
        },
        label: t('components.date_picker.last_30_days')
      },
    ]

    if (maxRange.value > 30) {
      base.push({
        id: '6',
        label: t('components.date_picker.last_60_days'),
        value: {
          start: dayjs().subtract(60, 'days').startOf('day').format('YYYY-MM-DD HH:mm:ss'),
          end: dayjs().endOf('day').format('YYYY-MM-DD HH:mm:ss'),
        }
      })
    }

    if (maxRange.value > 60) {
      base.push({
        id: '7',
        label: t('components.date_picker.last_90_days'),
        value: {
          start: dayjs().subtract(90, 'days').startOf('day').format('YYYY-MM-DD HH:mm:ss'),
          end: dayjs().startOf('day').format('YYYY-MM-DD HH:mm:ss'),
        }

      })
    }

    // if (maxRange.value > 90) {
    //   base.push({
    //     id: '8',
    //     label: t('components.date_picker.last_180_days'),
    //     value: {
    //       start: dayjs().subtract(180, 'days').startOf('day').toDate(),
    //       end: dayjs().startOf('day').toDate(),
    //     }
    //   })
    // }

    return base
  })

  return {
    maxRange,
    minDate,
    shortcuts
  }
}
