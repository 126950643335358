<script setup>
import { onMounted, reactive, ref, watch } from 'vue'
import { toast } from 'vue-sonner'
import { BanknotesIcon } from '@heroicons/vue/24/solid'
import { useRoute, } from 'vue-router'
import { useHead } from '@unhead/vue'
import { useI18n } from 'vue-i18n'
import CardStat from '@/components/common/CardStat.vue'
import SectionHeading from '@/components/common/SectionHeading.vue'
import { formatCurrency } from '@/helpers/currency'
import { transactionService } from '@/services/transaction'
import { walletService } from '@/services/wallet'
import WithdrawModal from '@/components/partials/WithdrawModal.vue'
import TransactionTable from '@/components/partials/transactions/TransactionTable.vue'
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select'

const route = useRoute()
const { t } = useI18n()
useHead({
  title: t('pages.referral.transactions.title')
})

// search
const count = ref(0)
const currency = ref('brl')

const wallet = reactive({
  balance: 0,
  pending: 0,
  pix_key: '',
  pix_type: ''
})
const transactions = ref([])
const isLoading = ref(false)
const hasError = ref(false)
const balanceLoading = ref(false)

async function fetchBalance(params) {
  balanceLoading.value = true
  const { data } = await walletService.balance(params)
  wallet.pending = data.pending
  wallet.balance = data.balance
  wallet.pix_key = data.pix_key?.split(',')[0]
  balanceLoading.value = false
}

async function fetchTransactions(search) {
  isLoading.value = true
  try {
    const { data } = await transactionService.findAll(search)
    transactions.value = data.data
    count.value = data.count
  } catch (e) {
    hasError.value = true

    toast.error(e.response?.data?.message, {
      description: e.response?.data?.action || e.message,
    })
  } finally {
    isLoading.value = false
  }
}

onMounted(() => {
  fetchBalance({
    currency: currency.value
  })
})

watch(
  () => [route.query],
  () => {
    fetchTransactions({
      page: route.query.page,
      limit: route.query.limit
    })
  },
  {
    immediate: true
  }
)

watch(currency, () => {
  fetchBalance({
    currency: currency.value
  })
})

function refresh() {
  fetchBalance()
  fetchTransactions({
    page: 1,
    limit: route.query.limit
  })
}
</script>

<template>
  <div class="space-y-5">
    <SectionHeading
      title="Transações"
      description="Lista de todas as transações realizadas, saques e depósitos."
    >
      <template #actions>
        <div class="flex items-center gap-2">
          <Select
            id="currency"
            v-model="currency"
            name="currency"
          >
            <SelectTrigger class="min-w-36">
              <SelectValue placeholder="Selecione uma moeda" />
            </SelectTrigger>
            <SelectContent position="popper">
              <SelectItem
                v-for="item in [{
                  name: 'R$',
                  value: 'brl',
                }, {
                  name: 'US$',
                  value: 'usd',
                }]"
                :key="item.name"
                :value="item.value"
              >
                Moeda: {{ item.name }}
              </SelectItem>
            </SelectContent>
          </Select>
          <WithdrawModal
            :wallet="wallet"
            :currency="currency"
            @withdraw="refresh"
            @pix="fetchBalance"
          />
        </div>
      </template>
    </SectionHeading>

    <div class="mt-5">
      <div class="grid grid-cols-1 gap-5 sm:grid-cols-3">
        <CardStat
          :icon="BanknotesIcon"
          title="Saldo"
          :subtitle="formatCurrency(wallet.balance, currency)"
          :loading="balanceLoading"
        />
        <CardStat
          :icon="BanknotesIcon"
          title="Saldo pendente"
          :subtitle="formatCurrency(wallet.pending, currency)"
          :loading="balanceLoading"
        />
      </div>
      <div class="mt-5">
        <TransactionTable
          :transactions="transactions"
          :is-loading="isLoading"
          :has-error="hasError"
          :count="count"
        />
      </div>
    </div>
  </div>
</template>
