<script setup>
import { PlusIcon } from '@heroicons/vue/24/solid'
import { reactive, ref, watch } from 'vue'
import { toast } from 'vue-sonner'
import { useI18n } from 'vue-i18n'
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger
} from '@/components/ui/dialog'
import Button from '@/components/ui/button/Button.vue'
import { walletService } from '@/services/wallet'
import CurrencyInput from '@/components/base/CurrencyInput.vue'
import BaseRadio from '@/components/base/BaseRadio.vue'
import Label from '@/components/ui/label/Label.vue'
import Input from '@/components/ui/input/Input.vue'
import ErrorMessage from '@/components/ui/error-message/ErrorMessage.vue'
import Field from '@/components/ui/field/Field.vue'
import Description from '@/components/ui/description/Description.vue'

const props = defineProps({
  wallet: Object,
  currency: String
})
const emit = defineEmits(['withdraw', 'pix'])
const { t } = useI18n()

const isLoading = ref(false)
const errors = ref({})
const isOpen = ref(false)
const withdrawErrors = ref([])
const isAddingPixKey = ref(false)

const pixForm = reactive({
  type: '',
  key: ''
})

const withdrawForm = reactive({
  amount: 0
})

async function addPixKey() {
  try {
    isAddingPixKey.value = true
    await walletService.addPixKey({
      pix_key: `${pixForm.key},${pixForm.type}`
    })

    toast.success(t('components.withdraw_modal.add_pix.toast.success.title'), {
      description: t('components.withdraw_modal.add_pix.toast.success.description'),
    })

    emit('pix')
  } catch (e) {
    toast.error(e.response?.data?.message, {
      description: e.response?.data?.action || e.message,
    })
  } finally {
    isAddingPixKey.value = false
  }
}

async function confirmWithdraw() {
  if (!props.wallet.pix_key) {
    return await addPixKey()
  }

  isLoading.value = true
  try {
    const { data } = await walletService.withdraw({
      amount: withdrawForm.amount,
      currency: props.currency
    })

    toast.success(t('components.withdraw_modal.withdraw.toast.success.title'), {
      description: t('components.withdraw_modal.withdraw.toast.success.description'),
    })

    isOpen.value = false
    emit('withdraw')
  } catch (e) {
    if (e.response?.status === 422) {
      withdrawErrors.value = e.response.data.data
    }

    toast.error(e.response?.data?.message, {
      description: e.response?.data?.action || e.message,
    })
  } finally {
    isLoading.value = false
  }
}

watch(isOpen, (value) => {
  if (value) {
    errors.value = {}
    pixForm.type = ''
    pixForm.key = ''
  }
})
</script>

<template>
  <Dialog v-model:open="isOpen">
    <DialogTrigger>
      <Button>
        <PlusIcon class="size-5" />
        <span class="ml-2">
          {{ $t('components.withdraw_modal.button') }}
        </span>
      </Button>
    </DialogTrigger>
    <DialogContent>
      <DialogHeader>
        <DialogTitle>
          {{ $t('components.withdraw_modal.title') }}
        </DialogTitle>
        <DialogDescription>
          {{ $t('components.withdraw_modal.description') }}
        </DialogDescription>
      </DialogHeader>

      <div class="mt-4">
        <div
          v-if="!wallet.pix_key"
          class="space-y-6"
        >
          <div>
            <Field>
              <Label>
                {{ $t('components.withdraw_modal.key_type') }}
              </Label>
              <div class="flex gap-2">
                <BaseRadio
                  v-model="pixForm.type"
                  value="CPF/CNPJ"
                  label="CPF"
                />
                <BaseRadio
                  v-model="pixForm.type"
                  value="Telefone"
                  label="Telefone"
                />
                <BaseRadio
                  v-model="pixForm.type"
                  value="Aleatório"
                  label="Aleatório"
                />
                <BaseRadio
                  v-model="pixForm.type"
                  value="Email"
                  label="E-mail"
                />
              </div>
            </Field>
          </div>
          <div>
            <Field>
              <Label
                required
                for="pix-key"
              >
                {{ $t('components.withdraw_modal.key') }}
              </Label>
              <Input
                id="pix-key"
                v-model="pixForm.key"
                type="text"
                name="pix-key"
                required
              />
              <Description>
                Não será possível alterar a chave pix novamente
              </Description>
            </Field>
          </div>
        </div>

        <div
          v-else
          class="space-y-5"
        >
          <div>
            <Field>
              <CurrencyInput
                v-model="withdrawForm.amount"
                label="Valor"
                :options="{ currency: 'BRL' }"
              />
              <ErrorMessage :message="withdrawErrors?.amount" />
            </Field>
          </div>

          <div>
            <Field>
              <Label for="pix-key">Chave pix de destino</Label>
              <Input
                v-model="wallet.pix_key"
                type="text"
                :disabled="true"
              />
            </Field>
          </div>
        </div>
      </div>

      <DialogFooter class="mt-4">
        <DialogClose as-child>
          <Button
            type="button"
            variant="secondary"
            :disabled="isAddingPixKey || isLoading"
          >
            Cancelar
          </Button>
        </DialogClose>
        <Button
          :loading="isAddingPixKey || isLoading"
          :disabled="isLoading"
          @click="confirmWithdraw"
        >
          Salvar
        </Button>
      </DialogFooter>
    </DialogContent>
  </Dialog>
</template>
