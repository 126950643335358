<script setup>
import { ref, watch } from 'vue'
import dayjs from 'dayjs'
import { useRoute, useRouter } from 'vue-router'
import { useHead } from '@unhead/vue'
import { useI18n } from 'vue-i18n'
import DatePicker from '@/components/base/DatePicker.vue'
import SectionHeading from '@/components/common/SectionHeading.vue'
import { usePresetDates } from '@/composables/usePresetDates'
import RequestsPerDayChart from '@/components/partials/RequestsPerDayChart.vue'
import RequestsPerCountry from '@/components/partials/RequestsPerCountry.vue'
import RequestByDeniedReason from '@/components/partials/RequestByDeniedReason.vue'

const { t, locale } = useI18n()
useHead({
  title: t('pages.reports.title')
})
const {
  minDate,
  shortcuts
} = usePresetDates()
const router = useRouter()
const route = useRoute('campaign/:id/reports')

const range = ref({
  start: route.query.start_date || dayjs().startOf('day').format('YYYY-MM-DD HH:mm:ss'),
  end: route.query.end_date || dayjs().endOf('day').format('YYYY-MM-DD HH:mm:ss')
})

watch(range, () => {
  router.push({
    query: {
      ...route.query,
      start_date: range.value.start,
      end_date: range.value.end,
    }
  })
}, {
  deep: true
})
</script>

<template>
  <div class="space-y-5">
    <div class="space-y-2">
      <SectionHeading
        :title="t('pages.reports.title')"
      >
        <template #actions>
          <div class="w-72">
            <DatePicker
              v-model="range"
              :presets="shortcuts"
              :min="minDate"
              range
              :locale="locale"
            />
          </div>
        </template>
      </SectionHeading>
    </div>

    <div class="space-y-5">
      <RequestsPerDayChart
        :campaign-id="$route.params.id"
        :start-date="startDate"
        :end-date="endDate"
      />

      <RequestsPerCountry
        :campaign-id="$route.params.id"
        :start-date="startDate"
        :end-date="endDate"
      />

      <RequestByDeniedReason
        :campaign-id="$route.params.id"
        :start-date="startDate"
        :end-date="endDate"
      />
    </div>
  </div>
</template>
