<script setup>
import { ref } from 'vue'
import { toast } from 'vue-sonner'
import { TrashIcon } from '@heroicons/vue/24/solid'
import { useI18n } from 'vue-i18n'
import { campaignService } from '@/services/campaign'
import {
  AlertDialog,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from '@/components/ui/alert-dialog'
import { Button } from '@/components/ui/button'
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '@/components/ui/tooltip'
import { Card, CardContent } from '@/components/ui/card'

const props = defineProps({
  id: {
    type: [String],
    required: true
  },
  name: {
    type: [String],
    required: true
  }
})
const emit = defineEmits(['onDeleted'])
const { t } = useI18n()

const isLoading = ref(false)
const isOpen = ref(false)

async function deleteCampaign(id) {
  isLoading.value = true

  try {
    await campaignService.delete(id)

    toast.success(t('components.campaign_delete_modal.toast.success.title'), {
      description: t('components.campaign_delete_modal.toast.success.description'),
    })

    isOpen.value = false
    emit('onDeleted', id)
  } catch (e) {
    toast.error(e.response?.data?.message, {
      description: e.response?.data?.action || e.message,
    })
  } finally {
    isLoading.value = false
  }
}
</script>

<template>
  <AlertDialog v-model:open="isOpen">
    <AlertDialogTrigger as-child>
      <TooltipProvider>
        <Tooltip>
          <TooltipTrigger as-child>
            <Button
              variant="ghost"
              size="xs"
              @click="isOpen = true"
            >
              <TrashIcon class=" h-5 text-muted-foreground" />
            </Button>
          </TooltipTrigger>
          <TooltipContent>
            <p>
              {{ $t('common.delete') }}
            </p>
          </TooltipContent>
        </Tooltip>
      </TooltipProvider>
    </AlertDialogTrigger>
    <AlertDialogContent>
      <AlertDialogHeader>
        <AlertDialogTitle>
          {{ $t('components.campaign_delete_modal.title') }}
        </AlertDialogTitle>
        <AlertDialogDescription>
          {{ $t('components.campaign_delete_modal.description') }}
        </AlertDialogDescription>
      </AlertDialogHeader>

      <Card>
        <CardContent class="bg-muted p-2">
          {{ props.name }}
        </CardContent>
      </Card>

      <AlertDialogFooter>
        <AlertDialogCancel :disabled="isLoading">
          {{ $t('common.cancel') }}
        </AlertDialogCancel>
        <Button
          variant="destructive"
          :loading="isLoading"
          :disabled="isLoading"
          @click="deleteCampaign(props.id)"
        >
          {{ $t('common.delete') }}
        </Button>
      </AlertDialogFooter>
    </AlertDialogContent>
  </AlertDialog>
</template>
