import client from '../plugins/axios'

export const campaignService = {
  resource: '/campaigns',
  findAll(params = {}) {
    return client.get(`${this.resource}`, {
      params
    })
  },
  findById(id) {
    return client.get(`${this.resource}/${id}`)
  },
  create(data) {
    return client.post(`${this.resource}`, data)
  },
  update(id, data) {
    return client.put(`${this.resource}/${id}`, data)
  },
  delete(id) {
    return client.delete(`${this.resource}/${id}`)
  },
  requests(campaignId, params = {}) {
    return client.get(`${this.resource}/${campaignId}/requests`, {
      params
    })
  },
  downloadFile(id, type) {
    return client.get(`${this.resource}/${id}/download?type=${type}`)
  },
  clone(id) {
    return client.post(`${this.resource}/${id}/clone`)
  },
  toggleStatus(id) {
    return client.put(`${this.resource}/${id}/toggle-status`)
  }
}
