<script setup>
import { computed } from 'vue'
import {
  Table,
  TableBody,
  TableCaption,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@/components/ui/table'

const props = defineProps({
  name: {
    type: String,
    default: 'DomainCnameTable',
  },
})

const cname = computed(() => {
  return window.location.href.includes('cloakup.dev') ? 'domains.cloakup.dev' : 'domains.cloakup.me'
})

const subDomain = computed(() => {
  return props.name.split('.')[0]
})
</script>

<template>
  <Table>
    <TableHeader>
      <TableRow>
        <TableHead class="w-[100px]">
          Type
        </TableHead>
        <TableHead>Name</TableHead>
        <TableHead>Value</TableHead>
      </TableRow>
    </TableHeader>
    <TableBody>
      <TableRow>
        <TableCell class="font-medium">
          CNAME
        </TableCell>
        <TableCell>{{ subDomain }}</TableCell>
        <TableCell>
          {{ cname }}
        </TableCell>
      </TableRow>
    </TableBody>
  </Table>
</template>
