<script setup>
import { ref } from 'vue'
import { toast } from 'vue-sonner'
import { TrashIcon } from '@heroicons/vue/24/solid'
import { useI18n } from 'vue-i18n'
import {
  AlertDialog,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from '@/components/ui/alert-dialog'
import { Button } from '@/components/ui/button'
import { domainService } from '@/services/domain.js'

const props = defineProps({
  id: {
    type: String,
    required: true
  }
})
const emit = defineEmits(['deleted'])
const { t } = useI18n()

const isOpen = ref(false)
const isLoading = ref(false)

async function deleteDomain(id) {
  isLoading.value = true

  try {
    await domainService.delete(id)

    emit('deleted')

    toast.success(t('components.delete_domain_modal.toast.success.title'), {
      description: t('components.delete_domain_modal.toast.success.description'),
    })
  } catch (e) {
    toast.error(e.response?.data?.message, {
      description: e.response?.data?.action || e.message,
    })
  } finally {
    isLoading.value = false
    isOpen.value = false
  }
}
</script>

<template>
  <AlertDialog v-model:open="isOpen">
    <AlertDialogTrigger as-child>
      <Button variant="ghost">
        <TrashIcon class="size-5" />
      </Button>
    </AlertDialogTrigger>
    <AlertDialogContent>
      <AlertDialogHeader>
        <AlertDialogTitle>
          {{ $t('components.delete_domain_modal.title') }}
        </AlertDialogTitle>
        <AlertDialogDescription>
          {{ $t('components.delete_domain_modal.description') }}
        </AlertDialogDescription>
      </AlertDialogHeader>
      <AlertDialogFooter>
        <AlertDialogCancel :disabled="isLoading">
          {{ $t('common.cancel') }}
        </AlertDialogCancel>
        <Button
          variant="destructive"
          :loading="isLoading"
          :disabled="isLoading"
          @click="deleteDomain(props.id)"
        >
          {{ $t('components.delete_domain_modal.button') }}
        </Button>
      </AlertDialogFooter>
    </AlertDialogContent>
  </AlertDialog>
</template>
