<script setup>
import { loadStripe } from '@stripe/stripe-js'
import { computed, ref } from 'vue'
import { useRouter } from 'vue-router'
import { toast } from 'vue-sonner'
import { useI18n } from 'vue-i18n'
import { useHead } from '@unhead/vue'
import PageHeading from '@/components/common/PageHeading.vue'
import UpgradePlans from '@/components/partials/pay/UpgradePlans.vue'
import UpgradeResume from '@/components/partials/pay/UpgradeResume.vue'
import client from '@/plugins/axios'
import { subscriptionService } from '@/services/subscription'
import { useAuthStore } from '@/stores/auth'
import { useUserStore } from '@/stores/user'
import Button from '@/components/ui/button/Button.vue'
import CardContent from '@/components/ui/card/CardContent.vue'
import CardFooter from '@/components/ui/card/CardFooter.vue'
import Card from '@/components/ui/card/Card.vue'
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbPage,
  BreadcrumbSeparator,
} from '@/components/ui/breadcrumb'

const { t } = useI18n()
useHead({
  title: t('pages.upgrade.title')
})
const router = useRouter()
const store = useUserStore()
const authStore = useAuthStore()

const selectedPlan = ref(null)
const isPaying = ref(false)
const required3Ds = ref(false)
const isLoading3Ds = ref(false)
let stripe = null

const currentSubscription = computed(() => {
  return store.user.subscriptions?.find(
    s => s.id === router.currentRoute.value.params.id
  )
})
const isPaymentEnabled = computed(() => selectedPlan.value || isPaying.value)

async function setPlan(plan) {
  selectedPlan.value = plan
}

async function pay() {
  isPaying.value = true
  const payload = {
    plan_id: selectedPlan.value?.id
  }

  try {
    await subscriptionService.upgradeSubscription(
      currentSubscription.value.id,
      payload
    )

    await onSuccess()
  } catch (error) {
    if (error.response?.status === 400) {
      if (error.response.data?.data?.client_secret) {
        required3Ds.value = true
        isLoading3Ds.value = true
        try {
          const confirmResponse = await stripe.confirmCardPayment(
            error.response.data.data.client_secret
          )

          if (confirmResponse.error) {
            throw new Error(error.message)
          }

          if (confirmResponse.paymentIntent?.status !== 'succeeded') {
            throw new Error('Payment failed')
          }

          await client.post(
            `/subscriptions/${currentSubscription.value.id}/upgrade/confirm`,
            {
              paymentIntentId: confirmResponse.paymentIntent.id,
              planId: selectedPlan.value?.id
            }
          )

          await onSuccess()
        } catch (e) {
          toast.error(e.response?.data?.message, {
            description: e.response?.data?.action || e.message,
          })
        } finally {
          isLoading3Ds.value = false
        }
      }
    } else {
      toast.error(t('pages.upgrade.toast.error.title'), {
        description: t('pages.upgrade.toast.error.description'),
      })
    }
  } finally {
    isPaying.value = false
  }
}

async function onSuccess() {
  await authStore.checkAuth()

  toast.success(t('pages.upgrade.toast.success.title'), {
    description: t('pages.upgrade.toast.success.description'),
  })

  await router.push({
    name: 'Dashboard'
  })

  const userStore = useUserStore()
  userStore.updateUsage({
    ...userStore.usage,
    limit: selectedPlan.value.limit,
  })
}

async function setupCheckout() {
  stripe = await loadStripe(import.meta.env.VITE_STRIPE_PK)
}

await setupCheckout()
</script>

<template>
  <div class="space-y-5">
    <div class="space-y-2">
      <Breadcrumb>
        <BreadcrumbList>
          <BreadcrumbItem>
            <BreadcrumbLink as-child>
              <RouterLink to="/">
                {{ $t('common.dashboard') }}
              </RouterLink>
            </BreadcrumbLink>
          </BreadcrumbItem>

          <BreadcrumbSeparator />

          <BreadcrumbItem>
            <BreadcrumbLink as-child>
              <RouterLink to="/settings">
                {{ $t('pages.settings.breadcrumb.settings') }}
              </RouterLink>
            </BreadcrumbLink>
          </BreadcrumbItem>

          <BreadcrumbSeparator />

          <BreadcrumbItem>
            <BreadcrumbLink as-child>
              <RouterLink to="/settings/subscriptions">
                {{ $t('pages.settings.subscription.title') }}
              </RouterLink>
            </BreadcrumbLink>
          </BreadcrumbItem>

          <BreadcrumbSeparator />

          <BreadcrumbItem>
            <BreadcrumbPage>
              {{ $t("pages.upgrade.title") }}
            </BreadcrumbPage>
          </BreadcrumbItem>
        </BreadcrumbList>
      </Breadcrumb>
      <PageHeading
        :title="t('pages.upgrade.title')"
      />
    </div>

    <Card>
      <CardContent>
        <div class="space-y-12 divide-y divide-border">
          <div v-if="store.cloakupSubscription">
            <UpgradePlans
              :current-plan="currentSubscription.plan"
              @select-plan="setPlan"
            />
          </div>

          <div
            v-if="selectedPlan?.id && currentSubscription.plan"
            class="pt-5"
          >
            <UpgradeResume
              :new-plan="selectedPlan"
              :current-plan="currentSubscription.plan"
              :current-subscription="currentSubscription"
            />
          </div>
        </div>
      </CardContent>

      <CardFooter v-if="selectedPlan?.id">
        <Button
          :loading="isPaying"
          :disabled="!isPaymentEnabled"
          @click="pay"
        >
          {{ $t("pages.upgrade.submit") }}
        </Button>
      </CardFooter>
    </Card>
  </div>
</template>
