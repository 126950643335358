import Pricing from '@/views/Pricing.vue'
import CheckoutView from '@/views/CheckoutView.vue'

export default [
  {
    path: '/checkout/:priceId',
    name: 'Checkout',
    component: CheckoutView,
    meta: {
      layout: 'checkout',
      requiresAuth: true
    },
    props: true
  },
  {
    path: '/plans',
    name: 'CheckoutPlans',
    component: Pricing,
    meta: {
      layout: 'default',
      requiresAuth: true
    }
  },

]
