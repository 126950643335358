import client from '../plugins/axios'

export const reportService = {
  resource: '/reports',
  groupByAllowed(options) {
    return client.get(`${this.resource}/group-by-allowed`, options)
  },
  groupByDeniedReason(options) {
    return client.get(`${this.resource}/group-by-denied-reason`, options)
  },
  groupByCountry(options) {
    return client.get(`${this.resource}/group-by-country`, options)
  }
}
