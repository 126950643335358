<script setup>
import { ref, watch } from 'vue'
import { toast } from 'vue-sonner'
import { useRoute, useRouter } from 'vue-router'
import { useI18n } from 'vue-i18n'
import { useHead } from '@unhead/vue'
import PageHeading from '@/components/common/PageHeading.vue'
import DomainCreateModal from '@/components/partials/domains/DomainCreateModal.vue'
import { domainService } from '@/services/domain'
import BaseTable from '@/components/base/BaseTable.vue'
import { formatDate } from '@/helpers/date'
import DomainDeleteModal from '@/components/partials/domains/DomainDeleteModal.vue'
import EmptyState from '@/components/common/EmptyState.vue'
import DomainVerifyModal from '@/components/partials/domains/DomainVerifyModal.vue'
import { Tabs, TabsList, TabsTrigger } from '@/components/ui/tabs'
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbPage,
  BreadcrumbSeparator,
} from '@/components/ui/breadcrumb'
import { useUserStore } from '@/stores/user'
import DomainStatusBadge from '@/components/partials/domains/DomainStatusBadge.vue'

const { t, locale } = useI18n()
useHead({
  title: t('pages.domains.title')
})

const domainsHeader = [
  {
    label: t('common.created_at'),
    value: 'created_at',
    format: value => formatDate(value, {
      locale: locale.value
    })
  },
  {
    label: t('common.name'),
    value: 'name'
  },
  {
    label: t('common.status'),
    value: 'verified',
  }
]

const userStore = useUserStore()
const route = useRoute()
const router = useRouter()

const domains = ref([])
const count = ref(0)
const isLoading = ref(false)
const hasError = ref(false)

async function fetchDomains(params) {
  isLoading.value = true
  try {
    const { data } = await domainService.findAll(params)
    domains.value = data.data
  } catch (e) {
    hasError.value = true

    toast.error(e.response?.data?.message, {
      description: e.response?.data?.action || e.message,
    })
  } finally {
    isLoading.value = false
  }
}

async function countDomains() {
  try {
    const { data } = await domainService.count()
    count.value = data.count
  } catch (e) {
    toast.error(e.response?.data?.message, {
      description: e.response?.data?.action || e.message,
    })
  }
}

function onStatusChanged(status) {
  router.push({
    query: {
      ...route.query,
      verified: status === 'all' ? undefined : status
    }
  })
}

function refresh() {
  fetchDomains({
    page: 1,
    limit: 10
  })
}

watch(
  () => [route.query],
  () => {
    fetchDomains({
      page: route.query.page,
      limit: route.query.limit,
      verified: route.query.verified
    })
  },
  {
    immediate: true
  }
)

countDomains()
</script>

<template>
  <div class="space-y-5">
    <div class="space-y-2">
      <Breadcrumb>
        <BreadcrumbList>
          <BreadcrumbItem>
            <BreadcrumbLink as-child>
              <RouterLink to="/">
                {{ $t("common.dashboard") }}
              </RouterLink>
            </BreadcrumbLink>
          </BreadcrumbItem>

          <BreadcrumbSeparator />

          <BreadcrumbItem>
            <BreadcrumbPage>
              {{ $t("pages.domains.title") }}
            </BreadcrumbPage>
          </BreadcrumbItem>
        </BreadcrumbList>
      </Breadcrumb>

      <PageHeading
        :title="`${$t('pages.domains.title')} (${count}/${userStore?.cloakupSubscription?.plan?.domains ?? 0})`"
      >
        <template #actions>
          <DomainCreateModal @created="refresh" />
        </template>
      </PageHeading>
    </div>

    <Tabs
      :default-value="$route.query.verified || 'all'"
      class="w-[400px]"
      @update:model-value="onStatusChanged"
    >
      <TabsList>
        <TabsTrigger value="all">
          {{ $t('common.all') }}
        </TabsTrigger>
        <TabsTrigger value="true">
          {{ $t('pages.domains.verified') }}
        </TabsTrigger>
        <TabsTrigger value="false">
          {{ $t('pages.domains.unverified') }}
        </TabsTrigger>
      </TabsList>
    </Tabs>

    <BaseTable
      :loading="isLoading"
      :error="hasError"
      :headers="domainsHeader"
      :columns="domains"
    >
      <template #col-verified="{ column }">
        <DomainStatusBadge
          :verified="column.verified"
        />
      </template>
      <template #actions="{ column }">
        <div class="flex items-center justify-end gap-2">
          <DomainVerifyModal
            v-if="!column.verified"
            :domain="column"
            @verified="refresh"
          />
          <DomainDeleteModal
            :id="column.id"
            @deleted="refresh"
          />
        </div>
      </template>
      <template #empty>
        <EmptyState
          :title="$t('pages.domains.empty_state.title')"
          :description="$t('pages.domains.empty_state.description')"
        />
      </template>
    </BaseTable>
  </div>
</template>
