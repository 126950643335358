<script setup>
import { computed, ref, watch } from 'vue'
import { XMarkIcon } from '@heroicons/vue/24/solid'
import Button from '@/components/ui/button/Button.vue'
import Input from '@/components/ui/input/Input.vue'
import Label from '@/components/ui/label/Label.vue'
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from '@/components/ui/dialog'
import Field from '@/components/ui/field/Field.vue'
import { Card } from '@/components/ui/card'
import CardContent from '@/components/ui/card/CardContent.vue'

const props = defineProps({
  params: {
    type: String,
  }
})

const emit = defineEmits(['created'])

const isOpen = defineModel({
  required: true
})

const params = ref([
  {
    key: '',
    value: ''
  }
])

const arrayToQueryString = computed(() => {
  return params.value
    .map(param => `${param.key}=${param.value}`)
    .join('&')
})

function queryStringToArray() {
  return props.params.split('&').map((param) => {
    const [key, value] = param.split('=')
    return {
      key,
      value: value ?? ''
    }
  })
}

function addParam() {
  params.value.push({
    key: '',
    value: ''
  })
}

function removeParam(index) {
  params.value.splice(index, 1)
}

/**
 *
 * @param params Array<{key: string, value: string}>
 * @returns {Record<string, string[]>}
 */
function parseToObject(params) {
  const paramsObject = {}
  for (let i = 0; i < params.length; i++) {
    const param = params[i]
    if (param.key) {
      if (!paramsObject[param.key]) {
        paramsObject[param.key] = []
      }
      paramsObject[param.key].push(param.value)
    }
  }

  return paramsObject
}

function saveParams() {
  emit('created', parseToObject(params.value))
  isOpen.value = false
}

function resetParams() {
  params.value = [
    {
      key: '',
      value: ''
    }
  ]
}

watch(isOpen, () => {
  if (!isOpen.value) {
    resetParams()
  } else {
    params.value = queryStringToArray()
  }
}, {
  immediate: true
})
</script>

<template>
  <div>
    <Dialog v-model:open="isOpen">
      <DialogContent class="max-h-[60dvh] grid-rows-[auto_minmax(0,1fr)_auto] p-0 ">
        <DialogHeader class="p-6">
          <DialogTitle>
            {{ $t('components.custom_params_modal.title') }}
          </DialogTitle>
          <DialogDescription>
            {{ $t('components.custom_params_modal.description') }}
          </DialogDescription>
        </DialogHeader>

        <div class="divide-y divide-border overflow-y-auto">
          <div
            class=" space-y-4 px-6 pb-6"
          >
            <div
              v-for="(param, index) in params"
              :key="index"
              class="flex gap-4"
            >
              <Field class="flex-1">
                <Label
                  :for="`param-${index}-key`"
                >
                  {{ $t('components.custom_params_modal.params') }}
                </Label>
                <Input
                  :id="`param-${index}-key`"
                  v-model="param.key"
                  type="text"
                  :name="`param-${index}-key`"
                />
              </Field>

              <Field class="flex-1">
                <Label :for="`param-${index}-value`">
                  {{ $t('common.value') }}
                </Label>
                <Input
                  :id="`param-${index}-value`"
                  v-model="param.value"
                  type="text"
                  :name="`param-${index}-value`"
                />
              </Field>

              <div class="mt-8">
                <Button
                  variant="secondary"
                  @click="removeParam(index)"
                >
                  <XMarkIcon class="size-4" />
                </Button>
              </div>
            </div>

            <Button
              type="button"
              @click="addParam"
            >
              {{ $t('components.custom_params_modal.add_params') }}
            </Button>
          </div>

          <div class="p-6">
            <Label>
              {{ $t('components.custom_params_modal.preview') }}
            </Label>

            <div
              v-if="arrayToQueryString"
              class="mt-4"
            >
              <Card class="bg-foreground/5">
                <CardContent class="p-2">
                  <p class="break-all">
                    {{ arrayToQueryString }}
                  </p>
                </CardContent>
              </Card>
            </div>
          </div>
        </div>

        <DialogFooter class="p-6">
          <DialogClose as-child>
            <Button
              type="button"
              variant="secondary"
            >
              {{ $t('common.cancel') }}
            </Button>
          </DialogClose>
          <Button
            type="submit"
            @click="saveParams"
          >
            {{ $t('common.save') }}
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  </div>
</template>
