<script setup>
import { ref, watch } from 'vue'
import { useRoute } from 'vue-router'
import { toast } from 'vue-sonner'
import { useI18n } from 'vue-i18n'
import { useHead } from '@unhead/vue'
import { plansService } from '@/services/plans'
import PlanCard from '@/components/partials/pricing/PlanCard.vue'
import PageHeading from '@/components/common/PageHeading.vue'
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbList,
  BreadcrumbPage,
  BreadcrumbSeparator
} from '@/components/ui/breadcrumb'
import Skeleton from '@/components/ui/skeleton/Skeleton.vue'
import { Card, CardFooter } from '@/components/ui/card'

const { t } = useI18n()

useHead({
  title: t('pages.plans.title')
})

const route = useRoute()

const plans = ref([])
const loading = ref(false)

/**
 *
 * @param {object} params
 * @param {string} params.product
 */
async function getPlans(params) {
  loading.value = true
  try {
    const { data } = await plansService.findAll(params)
    plans.value = data.filter(plan => plan.show)
  } catch (e) {
    toast.error(e.response?.data?.message, {
      description: e.response?.data?.action || e.message,
    })
  } finally {
    loading.value = false
  }
}

getPlans({
  product: route.query?.product?.toString() || 'cloakup'
})

watch(
  () => route.query.product,
  () => {
    getPlans({
      product: route.query?.product?.toString() || 'cloakup'
    })
  }
)
</script>

<template>
  <section>
    <div class="space-y-5">
      <div class="space-y-2">
        <Breadcrumb>
          <BreadcrumbList>
            <BreadcrumbLink as-child>
              <RouterLink to="/">
                {{ $t('common.dashboard') }}
              </RouterLink>
            </BreadcrumbLink>
            <BreadcrumbSeparator />

            <BreadcrumbItem>
              <BreadcrumbPage>
                {{ $t('pages.plans.title') }}
              </BreadcrumbPage>
            </BreadcrumbItem>
          </BreadcrumbList>
        </Breadcrumb>

        <PageHeading
          :title="$t('pages.plans.title')"
        />
      </div>

      <div
        v-if="loading"
        class=" grid grid-cols-1 gap-8 pt-12 lg:grid-cols-3"
      >
        <div
          v-for="i in 4"
          :key="i"
        >
          <Card
            class="relative flex flex-col"
          >
            <div class="space-y-6 p-6">
              <h2 class="text-lg font-medium leading-6 ">
                <Skeleton class="h-6 w-24" />
              </h2>

              <div>
                <span class="text-base font-medium text-muted-foreground line-through">
                  <Skeleton class="h-6 w-32" />
                </span>
                <div class="mt-2 flex items-center gap-2">
                  <Skeleton class="h-12 w-48" />
                  <span class="text-muted-foreground">/</span> <Skeleton class="h-6 w-12" />
                </div>
              </div>
            </div>
            <div class="border-t px-6 pb-8 pt-6">
              <Skeleton class="h-6 w-32" />
              <ul
                role="list"
                class="mt-6 space-y-6"
              >
                <li
                  v-for="i in 5"
                  :key="i"
                  class="flex"
                >
                  <Skeleton class="h-6 w-full" />
                </li>
              </ul>
            </div>
            <CardFooter class="mt-auto">
              <Skeleton class="h-12 w-full" />
            </CardFooter>
          </Card>
        </div>
      </div>
      <div
        v-else

        class="grid grid-cols-1 gap-8 pt-12 lg:grid-cols-3"
      >
        <template
          v-for="plan in plans"
          :key="plan.id"
        >
          <PlanCard :plan="plan" />
        </template>
      </div>
    </div>
  </section>
</template>
