<script setup>
import { ref, watch } from 'vue'
import { PlusIcon } from '@heroicons/vue/24/solid'
import BaseSpinner from '@/components/base/BaseSpinner.vue'
import { cardService } from '@/services/card'
import { ToggleGroup, ToggleGroupItem } from '@/components/ui/toggle-group'

const props = defineProps({
  errors: {
    default: null
  },
  cards: {
    default: []
  }
})

const model = defineModel({
  required: true
})

const isLoading = ref(false)
const hasError = ref(false)
const cards = ref(props.cards)

async function getAllCards() {
  isLoading.value = true
  hasError.value = false
  try {
    const { data } = await cardService.findAll({
      page: 1,
      limit: 50,
      gateway: 'stripe'
    })
    cards.value = [...cards.value, ...data.data]

    if (cards.value.length === 0) {
      model.value = 'new'
      return
    }

    const defaultCard = cards.value.find(card => card.default)
    if (defaultCard) {
      model.value = defaultCard?.token
    } else {
      model.value = cards.value[0]?.token
    }
  } catch (error) {
    hasError.value = true
  } finally {
    isLoading.value = false
  }
}

getAllCards()

watch(
  () => props.cards,
  () => {
    cards.value = [...cards.value, ...props.cards]
  }
)
</script>

<template>
  <div>
    <div
      v-if="isLoading"
      class="flex flex-col items-center justify-center rounded-lg bg-background py-12"
    >
      <div>
        <BaseSpinner />
      </div>
    </div>
    <div
      v-else-if="hasError"
      class="flex flex-col items-center justify-center rounded-lg bg-red-100 py-12"
    >
      <span class="text-sm font-semibold text-red-500">
        {{ $t('components.available_cards.failed_to_load') }}
      </span>
    </div>
    <div v-else>
      <div>
        <ul
          role="list"
          class="space-y-3"
        >
          <ToggleGroup
            v-model="model"
            type="single"
            class="flex w-full flex-col"
          >
            <ToggleGroupItem
              value="new"
              class="flex w-full justify-start rounded-lg border p-4 sm:p-6"
            >
              <div class="flex items-center gap-2">
                <div>
                  <PlusIcon
                    class="h-8 w-auto sm:h-6 sm:shrink-0"
                    aria-hidden="true"
                  />
                </div>
                <div class="mt-3 sm:ml-7 sm:mt-0">
                  <div class="text-sm font-medium">
                    Novo cartão
                  </div>
                </div>
              </div>
            </ToggleGroupItem>
            <li
              v-for="card in cards"
              :key="card.token"
              class="w-full flex-1"
            >
              <ToggleGroupItem
                :value="card.token"
                class="flex w-full justify-start rounded-lg border p-4 sm:p-6"
              >
                <div class="flex items-center gap-2">
                  <div>
                    <svg
                      class="h-8 w-auto sm:h-6 sm:shrink-0"
                      viewBox="0 0 36 24"
                      aria-hidden="true"
                    >
                      <rect
                        width="36"
                        height="24"
                        fill="#224DBA"
                        rx="4"
                      />
                      <path
                        fill="#fff"
                        d="M10.925 15.673H8.874l-1.538-6c-.073-.276-.228-.52-.456-.635A6.575 6.575 0 005 8.403v-.231h3.304c.456 0 .798.347.855.75l.798 4.328 2.05-5.078h1.994l-3.076 7.5zm4.216 0h-1.937L14.8 8.172h1.937l-1.595 7.5zm4.101-5.422c.057-.404.399-.635.798-.635a3.54 3.54 0 011.88.346l.342-1.615A4.808 4.808 0 0020.496 8c-1.88 0-3.248 1.039-3.248 2.481 0 1.097.969 1.673 1.653 2.02.74.346 1.025.577.968.923 0 .519-.57.75-1.139.75a4.795 4.795 0 01-1.994-.462l-.342 1.616a5.48 5.48 0 002.108.404c2.108.057 3.418-.981 3.418-2.539 0-1.962-2.678-2.077-2.678-2.942zm9.457 5.422L27.16 8.172h-1.652a.858.858 0 00-.798.577l-2.848 6.924h1.994l.398-1.096h2.45l.228 1.096h1.766zm-2.905-5.482l.57 2.827h-1.596l1.026-2.827z"
                      />
                    </svg>
                  </div>
                  <div class="mt-3 sm:ml-4 sm:mt-0">
                    <div class="text-sm font-medium">
                      Ending with {{ card.last_four_digits }}
                    </div>
                  </div>
                </div>
              </ToggleGroupItem>
            </li>
          </ToggleGroup>
        </ul>
      </div>
    </div>
  </div>
</template>
