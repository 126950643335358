<script setup>
import 'intl-tel-input/build/css/intlTelInput.css'
import pt from 'intl-tel-input/i18n/pt'
import { onMounted, ref } from 'vue'
import intlTelInput from 'intl-tel-input'
import Input from '@/components/ui/input/Input.vue'

const props = defineProps({
  class: {
    type: null,
    required: false
  },
  invalid: {
    type: Boolean,
    required: false
  }
})

const model = defineModel({
  required: true
})

const iti = ref(null)

onMounted(() => {
  const input = document.querySelector('[data-phone-input]')
  iti.value = intlTelInput(input, {
    utilsScript: 'https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/23.0.4/js/utils.min.js',
    initialCountry: 'BR',
    preferredCountries: ['us', 'gb', 'co', 'de'],
    i18n: pt
  })
})
</script>

<template>
  <Input
    v-bind="$attrs"
    v-model="model"
    mode="international"
    data-phone-input
    class="py-2"
  />
</template>

<style>
.iti {
    width: 100%;
}

    .iti input, .iti__dropdown-content {
        background-color: hsl(var(--background));
    }

    .iti input, iti__search-input {
        @apply text-sm border-border
    }

    .iti, iti__country {
        @apply text-sm
    }

    :root {
        --iti-border-gray: hsl(var(--muted-foreground));
        --iti-border-gray: hsl(var(--border));
    }
</style>
