<script setup>
import { loadStripe } from '@stripe/stripe-js'
import { nextTick, onMounted, reactive, ref, watch } from 'vue'
import { toast } from 'vue-sonner'
import { PlusIcon } from '@heroicons/vue/24/solid'
import { useColorMode } from '@vueuse/core'
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger
} from '@/components/ui/dialog'
import { Skeleton } from '@/components/ui/skeleton'
import { cardService } from '@/services/card'
import { useUserStore } from '@/stores/user'
import Button from '@/components/ui/button/Button.vue'
import Label from '@/components/ui/label/Label.vue'
import Input from '@/components/ui/input/Input.vue'
import Field from '@/components/ui/field/Field.vue'

defineProps({
  show: {
    type: Boolean,
    default: false
  }
})
const emit = defineEmits(['newCard'])
const userStore = useUserStore()
const colorMode = useColorMode()

const isOpen = ref(false)

const errors = ref([])
const radar = ref(null)
const isLoading = ref(false)
const elementReady = reactive({
  number: false,
  exp: false,
  cvc: false
})
const holderName = ref('')

let stripe
async function setupCardElements() {
  await nextTick()
  if (!stripe) {
    return
  }

  const elements = stripe.elements({
    fonts: [
      {
        cssSrc: 'https://rsms.me/inter/inter.css'
      }
    ],
    locale: 'auto'
  })

  const cardNumberElement = document.getElementById('stripe-card')
  const cardExpiryElement = document.getElementById('stripe-exp')
  const cardCvcElement = document.getElementById('stripe-cvc')

  const themeDark = {
    color: '#fff',
    iconColor: '#fff',
  }

  const themeLight = {
    color: '#000',
    iconColor: '#000',
  }

  const style = {
    base: {
      fontFamily: 'Inter, Open Sans, Segoe UI, sans-serif',
      fontSmoothing: 'antialiased',
      ...(colorMode.value === 'dark' ? themeDark : themeLight)
    }
  }

  const cardNumber = elements.create('cardNumber', {
    showIcon: true,
    style
  })
  const cardExpiry = elements.create('cardExpiry', {
    style
  })
  const cardCvc = elements.create('cardCvc', {
    style
  })

  if (!cardNumberElement || !cardExpiryElement || !cardCvcElement) {
    return
  }

  cardNumber.mount(cardNumberElement)
  cardExpiry.mount(cardExpiryElement)
  cardCvc.mount(cardCvcElement)

  cardNumber.on('ready', () => {
    elementReady.number = true
  })

  cardNumber.on('change', (event) => {
    const displayError = document.getElementById('card-number-errors')
    if (!displayError) {
      return
    }

    if (event.error) {
      displayError.textContent = event.error.message
    } else {
      displayError.textContent = ''
    }
  })

  cardExpiry.on('ready', () => {
    elementReady.exp = true
  })

  cardExpiry.on('change', (event) => {
    const displayError = document.getElementById('card-exp-errors')
    if (!displayError) {
      return
    }

    if (event.error) {
      displayError.textContent = event.error.message
    } else {
      displayError.textContent = ''
    }
  })

  cardCvc.on('ready', () => {
    elementReady.cvc = true
  })

  cardCvc.on('change', (event) => {
    const displayError = document.getElementById('card-cvc-errors')
    if (!displayError) {
      return
    }

    if (event.error) {
      displayError.textContent = event.error.message
    } else {
      displayError.textContent = ''
    }
  })

  // Create a token or display an error when the form is submitted.
  const formElement = document.getElementById('payment-form')
  if (!formElement) {
    return
  }

  formElement.addEventListener('submit', async (event) => {
    event.preventDefault()
    isLoading.value = true

    if (!stripe) {
      return
    }
    const disableInputs = {
      disabled: true
    }

    cardCvc.update(disableInputs)
    cardExpiry.update(disableInputs)
    cardNumber.update(disableInputs)

    const enabledInput = {
      disabled: false
    }

    const result = await stripe.createPaymentMethod({
      type: 'card',
      card: cardNumber,
      billing_details: {
        name: holderName.value,
        email: userStore.user.email,
        ...(userStore.user?.phone && {
          phone: userStore.user.phone
        }),
        ...(userStore.user?.address && {
          address: {
            city: userStore.user.address?.city,
            country: userStore.user.address?.country,
            line1: `${userStore.user.address?.street}, ${
              userStore.user.address?.number
            }, ${userStore.user?.district ? userStore.user?.district : ''}`,
            line2: userStore.user.address?.complement,
            state: userStore.user.address?.state,
            postal_code: userStore.user.address?.postal_code
          }
        })
      },
      ...(radar.value && {
        radar_options: {
          session: radar.value,
        },
      }),
    })

    if (result.error) {
      isLoading.value = false

      toast.error('Erro!', {
        description: result.error.message
      })

      isLoading.value = false
      cardCvc.update(enabledInput)
      cardExpiry.update(enabledInput)
      cardNumber.update(enabledInput)

      return
    }

    try {
      const newCard = await attachPaymentMethod({
        card: {
          token: result.paymentMethod.id,
          last4: result.paymentMethod.card.last4,
          brand: result.paymentMethod.card.brand
        }
      })
      emit('newCard', newCard.data)
      closeModal()
    } catch (err) {
      toast.error('Erro!', {
        description: err.response?.data?.message || err.message
      })

      return
    } finally {
      isLoading.value = false
      cardCvc.update(enabledInput)
      cardExpiry.update(enabledInput)
      cardNumber.update(enabledInput)
    }
  })
}
/**
 *
 * @param {object} paymentMethod
 * @param {object} paymentMethod.card
 * @param {string} paymentMethod.card.token
 * @param {string} paymentMethod.card.last4
 * @param {string} paymentMethod.card.brand
 */
async function attachPaymentMethod(paymentMethod) {
  return await cardService.create(paymentMethod)
}

function closeModal() {
  errors.value = []
  isOpen.value = false
}

async function setupRadarSession() {
  const { radarSession, error } = await stripe.createRadarSession()
  if (error) {
    console.error(error)
  } else {
    radar.value = radarSession.id
  }
}

onMounted(async () => {
  stripe = await loadStripe(import.meta.env.VITE_STRIPE_PK)
})

watch(isOpen, (value) => {
  if (value) {
    setupRadarSession()
    setupCardElements()
  }
})
</script>

<template>
  <Dialog
    v-model:open="isOpen"
  >
    <DialogTrigger>
      <Button>
        <PlusIcon class="size-5" />
        <span class="ml-2">
          {{ $t('pages.settings.payment_methods.create.button') }}
        </span>
      </Button>
    </DialogTrigger>
    <DialogContent>
      <DialogHeader>
        <DialogTitle>
          {{ $t('pages.settings.payment_methods.create.title') }}
        </DialogTitle>
        <DialogDescription>
          {{ $t('pages.settings.payment_methods.create.description') }}
        </DialogDescription>
      </DialogHeader>

      <div class="mt-4">
        <form id="payment-form">
          <fieldset
            :disabled="isLoading"
            class="space-y-4"
          >
            <div class="grid grid-cols-1 gap-4 sm:grid-cols-6">
              <div class="sm:col-span-6">
                <Field>
                  <Label for="card-name">
                    {{ $t('components.payment_method.holder_name') }}
                  </Label>
                  <div>
                    <Skeleton
                      v-show="
                        !elementReady.number && !elementReady.cvc && !elementReady.exp
                      "
                      class="h-10 w-full rounded-md"
                    />
                    <Input
                      v-show="
                        elementReady.number && elementReady.cvc && elementReady.exp
                      "
                      id="card-name"
                      v-model="holderName"
                      name="card-name"
                      type="text"
                    />
                  </div>
                </Field>
              </div>
              <div class="stripe-custom-element sm:col-span-6">
                <Field>
                  <Label for="stripe-card">
                    {{ $t('components.payment_method.card_number') }}
                  </Label>
                  <div>
                    <Skeleton
                      v-show="!elementReady.number"
                      class="h-10 w-full rounded-md"
                    />
                    <div
                      v-show="elementReady.number"
                      id="stripe-card"
                      class="input"
                    />
                    <span
                      id="card-number-errors"
                      class="mt-2 text-sm text-red-600"
                    />
                  </div>
                </Field>
              </div>
              <div class="stripe-custom-element sm:col-span-3">
                <Field>
                  <Label for="stripe-exp">
                    {{ $t('components.payment_method.expiration_date') }}
                  </Label>

                  <div>
                    <Skeleton
                      v-show="!elementReady.exp"
                      class="h-10 w-full rounded-md"
                    />
                    <div
                      v-show="elementReady.exp"
                      id="stripe-exp"
                      class="input"
                    />
                    <span
                      id="card-exp-errors"
                      class="mt-2 text-sm text-red-600"
                    />
                  </div>
                </Field>
              </div>
              <div class="stripe-custom-element sm:col-span-3">
                <Field>
                  <Label for="stripe-cvc">
                    {{ $t('components.payment_method.cvc_code') }}
                  </Label>
                  <div>
                    <Skeleton
                      v-show="!elementReady.cvc"
                      class="h-10 w-full rounded-md"
                    />
                    <div
                      v-show="elementReady.cvc"
                      id="stripe-cvc"
                      class="input"
                    />
                    <span
                      id="card-cvc-errors"
                      class="mt-2 text-sm text-red-600"
                    />
                  </div>
                </Field>
              </div>
            </div>
          </fieldset>
        </form>
      </div>

      <DialogFooter class="mt-4">
        <DialogClose as-child>
          <Button
            type="button"
            variant="secondary"
            :disabled="isLoading"
          >
            {{ $t('common.cancel') }}
          </Button>
        </DialogClose>
        <Button
          form="payment-form"
          :loading="isLoading"
          :disabled="isLoading"
        >
          {{ $t('common.save') }}
        </Button>
      </DialogFooter>
    </DialogContent>
  </Dialog>
</template>

<style scoped>
.input {
  @apply rounded-md px-3 py-3 ring-border border-primary block w-full border-0 text-sm leading-6 caret-primary accent-primary shadow-sm ring-1 ring-inset text-white placeholder:text-muted-foreground  ;
}

.StripeElement--invalid {
  box-shadow: 0 0 0 1px hsl(var(--destructive)) inset;
  color: hsl(var(--destructive));
}

.StripeElement--focus {
  box-shadow: 0 0 0 1.5px hsl(var(--primary)) inset;
  color: red
}
</style>
