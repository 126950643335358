<script setup>
import { computed, ref, watch } from 'vue'
import jsVectorMap from 'jsvectormap'
import 'jsvectormap/dist/maps/world-merc'
import 'jsvectormap/src/scss/jsvectormap.scss'
import { toast } from 'vue-sonner'
import dayjs from 'dayjs'
import { useI18n } from 'vue-i18n'
import BaseBarList from '../base/BaseBarList.vue'
import EmptyCard from '../EmptyCard.vue'
import ProgressIndicator from '@/components/common/ProgressIndicator.vue'
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle
} from '@/components/ui/card'
import { formatNumber } from '@/helpers/format'
import { countryList } from '@/static/countryList'
import { reportService } from '@/services/report-service'
import { ScrollArea } from '@/components/ui/scroll-area'

const props = defineProps({
  campaignId: {
    type: String,
    required: false
  },
  startDate: {
    type: String,
    required: false,
  },
  endDate: {
    type: String,
    required: false,
  }
})

const { t } = useI18n()
const controller = new AbortController()

const map = ref(null)
const isLoading = ref(false)
const countries = ref({})

const countriesToArray = computed(() => {
  return Object.entries(countries.value).map(country => ({
    name: country[0],
    value: country[1],
  }))
})

async function fetchByCountry(params) {
  isLoading.value = true
  try {
    const { data } = await reportService.groupByCountry({
      params,
      signal: controller.signal
    })

    const obj = {}

    Object.entries(data).forEach((element) => {
      obj[element[1].country] = formatNumber(element[1].count)
    })

    countries.value = obj
  } catch (e) {
    if (e.message === 'canceled') {
      return
    }

    toast.error(e.response?.data?.message, {
      description: e.response?.data?.action || e.message,
    })
  } finally {
    isLoading.value = false
  }
}

const mapOptions = computed(() => {
  return {
    onRegionTooltipShow(event, tooltip, code) {
      const country = countryList.find(c => c.id === code)
      const countryName = country?.name ?? t('common.unknown')
      const accessCount = countries.value[code] ?? 0
      const accessText = t('access')

      tooltip.css({
        backgroundColor: '#FFFFFF',
        color: '#171717'
      })
      tooltip.text(
        `
        <div class="flex gap-2">
          <img src="/img/flags/${code.toLowerCase()}.svg" class="w-8" />
          <div>
            <h5 class="font-semibold">${countryName}</h5>
            <p class="text-xs text-muted-foreground">
              ${formatNumber(accessCount)} ${accessText}
            </p>
      `,
        true
      )
    },
    visualizeData: {
      scale: ['#93c5fd', '#3b82f6', '#1d4ed8'],
      values: countries.value
    }
  }
})

watch(() => [props.startDate, props.endDate, props.campaignId], () => {
  if (!props.campaignId) {
    return
  }

  fetchByCountry({
    start_date: props.startDate,
    end_date: props.endDate,
    campaign: props.campaignId
  })
}, {
  immediate: true
})
</script>

<template>
  <Card>
    <CardHeader class="flex flex-col items-stretch space-y-0 border-b p-0 sm:flex-row">
      <div class="flex flex-1 flex-col justify-center gap-1 px-6 py-5 sm:py-6">
        <CardTitle>
          {{ $t('components.requests_per_country.title') }}
        </CardTitle>
        <CardDescription>
          {{ $t('components.requests_per_country.description') }}
        </CardDescription>
      </div>
    </CardHeader>
    <div class="h-2">
      <ProgressIndicator v-if="isLoading" />
    </div>

    <CardContent class="p-0">
      <EmptyCard
        v-if="Object.keys(countries).length === 0"
        class="p-6"
        :message="!campaignId ? $t('pages.reports.missing_selected_campaign') : undefined"
      />
      <div
        v-else
        class="grid grid-cols-1 sm:grid-cols-4"
      >
        <div class="col-span-3">
          <vuevectormap
            ref="map"
            width="100%"
            class="w-full"
            map="world_merc"
            :options="mapOptions"
          />
        </div>
        <div class="relative border-l-0 border-t p-3 sm:border-l sm:border-t-0">
          <div class="absolute -left-[0.5px] -top-2 hidden h-2 w-[0.1px] bg-border sm:block" />
          <ScrollArea class="h-[300px] pr-4">
            <BaseBarList
              :list="countriesToArray"
              :loading="isLoading"
            >
              <template #name="{ item }">
                <div class="flex gap-2">
                  <img
                    class="inline-block w-6"
                    :src="`/img/flags/${item?.toLowerCase()}.svg`"
                    :alt="item"
                    loading="lazy"
                  >
                  <div>{{ item }}</div>
                </div>
              </template>
            </BaseBarList>
          </ScrollArea>
        </div>
      </div>
    </CardContent>
  </Card>
</template>

<style>
.jvm-container {
  width: 100% !important;
}
</style>
