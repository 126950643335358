<script setup lang="ts">
import { onMounted, ref, watch } from 'vue'
import { ToggleGroup, ToggleGroupItem } from '@/components/ui/toggle-group'
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '@/components/ui/tooltip'

const model = defineModel({
  required: true,
})

const selected = ref(undefined)

function bindSelected(value) {
  const platforms = ['facebook', 'tiktok', 'google', 'kwai']

  platforms.forEach((platform) => {
    model.value[platform] = (platform === value)
  })
}

function bindModel(value) {
  const platforms = ['facebook', 'tiktok', 'google', 'kwai']

  for (const platform of platforms) {
    if (value[platform]) {
      selected.value = platform
      break
    }
  }
}

watch(selected, () => {
  bindSelected(selected.value)
})

onMounted(() => {
  bindModel(model.value)
})
</script>

<template>
  <ToggleGroup
    v-model="selected"
    type="single"
  >
    <ToggleGroupItem
      value="facebook"
      aria-label="Toggle facebook"
      class="size-16"
    >
      <TooltipProvider>
        <Tooltip>
          <TooltipTrigger as-child>
            <img
              src="/img/networks/facebook.svg"
              alt="Facebook"
              class="size-12 grayscale dark:invert"
            >
          </TooltipTrigger>
          <TooltipContent>
            <p>Facebook</p>
          </TooltipContent>
        </Tooltip>
      </TooltipProvider>
    </ToggleGroupItem>
    <ToggleGroupItem
      value="tiktok"
      aria-label="Toggle tiktok"
      class="size-16"
    >
      <TooltipProvider>
        <Tooltip>
          <TooltipTrigger as-child>
            <img
              src="/img/networks/tiktok.svg"
              alt="Tiktok"
              class="size-12 grayscale dark:invert"
            >
          </TooltipTrigger>
          <TooltipContent>
            <p>Tiktok</p>
          </TooltipContent>
        </Tooltip>
      </TooltipProvider>
    </ToggleGroupItem>
    <ToggleGroupItem
      value="google"
      aria-label="Toggle google"
      class="size-16"
    >
      <TooltipProvider>
        <Tooltip>
          <TooltipTrigger as-child>
            <img
              src="/img/networks/googleads.svg"
              alt="Google Ads"
              class="size-12 grayscale "
            >
          </TooltipTrigger>
          <TooltipContent>
            <p>Google Ads</p>
          </TooltipContent>
        </Tooltip>
      </TooltipProvider>
    </ToggleGroupItem>
    <ToggleGroupItem
      value="kwai"
      aria-label="Toggle kwai"
      class="size-16"
    >
      <TooltipProvider>
        <Tooltip>
          <TooltipTrigger as-child>
            <img
              src="/img/networks/kwai.svg"
              alt="Facebook"
              class="size-12 grayscale dark:invert"
            >
          </TooltipTrigger>
          <TooltipContent>
            <p>Kwai</p>
          </TooltipContent>
        </Tooltip>
      </TooltipProvider>
    </ToggleGroupItem>
    <!-- <ToggleGroupItem
      value="taboola"
      aria-label="Toggle taboola"
      class="size-16"
    >
      <TooltipProvider>
        <Tooltip>
          <TooltipTrigger as-child>
            <img
              src="/img/networks/taboola.svg"
              alt="Taboola"
              class="size-12 grayscale dark:invert"
            >
          </TooltipTrigger>
          <TooltipContent>
            <p>Taboola</p>
          </TooltipContent>
        </Tooltip>
      </TooltipProvider>
    </ToggleGroupItem> -->
  </ToggleGroup>
</template>
