import dayjs from 'dayjs'

function formatDate(date, {
  locale = 'pt-BR',
}) {
  if (!date) {
    return
  }

  const d = new Date(date)

  const dateFormatter = new Intl.DateTimeFormat(locale, {
    day: 'numeric',
    month: 'short'
  })
  const formattedDate = dateFormatter.format(d)

  // Formata a hora e minutos
  const timeFormatter = new Intl.DateTimeFormat(locale, {
    hour: '2-digit',
    minute: '2-digit',
    hour12: false
  })
  const formattedTime = timeFormatter.format(d)

  // Combina a data e hora formatadas
  return `${formattedDate} ${formattedTime}`
}

function timeToRenew(date) {
  const diff = new Date(date).getTime() - new Date().getTime()
  const days = Math.floor(diff / (1000 * 60 * 60 * 24))

  return `${days}`
}

function fillMissingHours(start, end, data) {
  const filledData = []
  const dateMap = new Map()

  data.forEach((item) => {
    const normalizedDate = `${item.day.replace(' ', 'T')}.000Z`
    const date = dayjs(normalizedDate.replace(' ', 'T'))
      .format('HH:00')

    dateMap.set(date, {
      allowed: item.allowed_count,
      denied: item.denied_count,
      date,
    })
  })

  const currentTime = new Date(start) // Converte a string de start para data no local timezone
  const endTime = new Date(end) // Converte a string de end para data no local timezone

  // Iterar de hora em hora, preenchendo os dados ausentes
  while (currentTime <= endTime) {
    const formattedHour = dayjs(currentTime).format('HH:00') // Formata para 'HH:00'

    // Verifica se o horário já existe no mapa, caso contrário, preenche com zeros
    if (dateMap.has(formattedHour)) {
      filledData.push(dateMap.get(formattedHour))
    } else {
      filledData.push({
        allowed: 0,
        denied: 0,
        date: formattedHour,
      })
    }

    // Incrementa para a próxima hora
    currentTime.setHours(currentTime.getHours() + 1)
  }

  return filledData
}

function fillMissingDates(start, end, data) {
  const filledData = []
  const dateMap = new Map()

  data.forEach((item) => {
    const date = dayjs(item.day).format('YYYY-MM-DD')
    dateMap.set(date, {
      allowed: item.allowed_count,
      denied: item.denied_count,
      date,
    })
  })

  let currentDate = new Date(start)
  const endDate = new Date(end)

  // Convert dates to UTC to avoid timezone issues
  currentDate = new Date(Date.UTC(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate()))
  const utcEndDate = new Date(Date.UTC(endDate.getFullYear(), endDate.getMonth(), endDate.getDate()))

  while (currentDate <= utcEndDate) {
    const dateStr = currentDate.toISOString().split('T')[0]
    if (dateMap.has(dateStr)) {
      filledData.push(dateMap.get(dateStr))
    } else {
      filledData.push({
        allowed: 0,
        denied: 0,
        date: dateStr,
      })
    }
    // Move to the next day in UTC
    currentDate.setUTCDate(currentDate.getUTCDate() + 1)
  }

  return filledData
}

export { formatDate, timeToRenew, fillMissingDates, fillMissingHours }
