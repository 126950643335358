<script setup>
import { computed, onMounted, ref } from 'vue'
import { useRouter } from 'vue-router'
import { toast } from 'vue-sonner'
import { useAuthStore } from '@/stores/auth'
import { authService } from '@/services/auth'

const router = useRouter()
const authStore = useAuthStore()

const meta = computed(() => router.currentRoute.value.query)

async function onSuccess(response) {
  try {
    const { credential } = response
    await authService.google({
      credential,
      meta: meta.value,
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      lang: window.navigator.language
    })

    authStore.token = '1'
    await authStore.checkAuth()

    await router.push({
      name: 'Dashboard'
    })
  } catch (e) {
    toast.error(e.response?.data?.message, {
      description: e.response?.data?.action || e.message,
    })
  }
}

onMounted(() => {
  window.google?.accounts?.id.initialize({
    client_id: import.meta.env.VITE_GOOGLE_AUTH_CLIENT_ID,
    callback: onSuccess
  })
})
</script>

<template>
  <div class="flex w-full items-center justify-center">
    <div
      class="g_id_signin"
      data-type="standard"
      data-size="large"
      data-width="800"
      data-theme="filled_black"
      data-data-context="signup"
    />
  </div>
</template>
